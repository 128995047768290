function Footer() {
  return (
    <>
      <footer className="py-5">
        <div className="container">
          <div className="footer-menu">
            <img src="images/logo.png" alt="logo" style={{ maxWidth: "20%" }} />
          </div>
          <div className="col-md-6 copyright">
            <p>© 2024 ConsumeWisely. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
